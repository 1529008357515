<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        <template v-slot:item="{ item }">
            <v-breadcrumbs-item
                href="#"
                :disabled="item.disabled"
            >
                {{ item.text }}
            </v-breadcrumbs-item>
        </template>
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
                        <v-card-title>Update Car Park Rate</v-card-title>
                        <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('update')" lazy-validation>
                            <v-container class="fill-height"
                            fluid>
                            <v-overlay
                        :absolute="true"
                        :opacity="1"
                        :z-index="999"
                        :value="overlay_loader"
                        color="#D2D7DF"
                        >
                        <v-progress-circular indeterminate size="100"></v-progress-circular>
                        </v-overlay>
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-text-field
                                            dense
                                            outlined
                                            v-model="form.cpid"
                                            label="CP ID"
                                            required
                                            disabled
                                            hide-details='auto'
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-text-field
                                            dense
                                            outlined
                                            v-model="form.cpcode"
                                            label="CP Code"
                                            required
                                            disabled
                                            hide-details='auto'
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-text-field
                                            dense
                                            outlined
                                            v-model="form.carparkname"
                                            label="Car Park Name"
                                            required
                                            disabled
                                            hide-details='auto'
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-text-field
                                            dense
                                            outlined
                                            v-model="form.ratecardid"
                                            label="Rate Card ID"
                                            required
                                            disabled
                                            hide-details='auto'
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-text-field background-color="white"
                                            dense
                                            outlined
                                            v-model="form.ratecardname.name"
                                            :rules="form.ratecardname.rule"
                                            label="Rate Card Name *"
                                            :disabled="readonly"
                                            required
                                            hide-details='auto'
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-text-field background-color="white"
                                            dense
                                            outlined
                                            v-model="form.entrygrace.name"
                                            :rules="form.entrygrace.rule"
                                            label="Entry Grace Period (minutes) *"
                                            :disabled="readonly"
                                            required
                                            hide-details='auto'
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-text-field background-color="white"
                                            dense
                                            outlined
                                            v-model="form.exitgrace.name"
                                            :rules="form.exitgrace.rule"
                                            label="Exit Grace Period (minutes) *"
                                            :disabled="readonly"
                                            required
                                            hide-details='auto'
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <div v-bind:class="[ focuscutoff ? 'v-input--is-focused primary--text' : '', readonly ? 'v-input--is-disabled' : '' ]"
                                            class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
                                            <div class="v-input__control">
                                                <div class="v-input__slot white">
                                                    <fieldset aria-hidden="true"><legend :style="focuscutoff || form.cutofftime ? {'width': '110px'} : {'width': '0px'}"><span>&#8203;</span></legend></fieldset>
                                                    <div class="v-text-field__slot">
                                                    <label for="input-starthour" v-bind:class="[ focuscutoff || form.cutofftime ? 'primary--text v-label--active' : '' ]" class="v-label theme--light" style="left: 0px; right: auto; position: absolute;">Cut Off Time (HH:MM) *</label>
                                                    <vue-timepicker placeholder=" " style="padding: none" id="input-starthour" input-class="vtimepicker" @focus="focuscutoff = true" :disabled="readonly"
                                                                    @blur="focuscutoff = false" v-model="form.cutofftime" format="h:mm a" manual-input>
                                                        <template v-slot:clearButton>
                                                            <v-icon x-small>fa fa-times</v-icon>
                                                        </template>
                                                    </vue-timepicker>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form.createddatetime"
                                        label="Created Date"
                                        required
                                        disabled
                                        hide-details
                                    ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                    <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form.updateddatetime"
                                        label="Updated Date"
                                        required
                                        disabled
                                        hide-details
                                    ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <span class="text-body-2">Status</span>
                                        <v-switch
                                        color="success"
                                        class="col-1 pl-0"
                                        v-model="form.activestatus"
                                        :label="`${form.activestatus ? 'Enabled' : 'Disabled'}`"
                                        :disabled="readonly"
                                        hide-details="auto"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <span class="text-body-2">Is Default</span>
                                        <v-switch
                                        color="success"
                                        class="col-1 pl-0"
                                        v-model="form.defaultchosenratecardforcarpark"
                                        :label="`${form.defaultchosenratecardforcarpark == 1 ? 'Yes' : 'No'}`"
                                        hide-details="auto"
                                        :disabled="items.defaultchosenratecardforcarpark == 1 || readonly"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-divider class="mb-4"></v-divider>
                                        <span class="text-body-2">Rate Setting</span>
                                        <v-data-table
                                            calculate-widths
                                            :headers="headers"
                                            :items="form.days"
                                            hide-default-footer
                                            :loading="tableloading"
                                            loading-text= "Loading Data... Please wait"
                                            :items-per-page="100"
                                            id="main-table"
                                            disable-sort
                                            hide-default-header
                                            dense
                                        >
                                            <template slot="item" slot-scope="props">
                                                <tr>
                                                    <td>{{props.item.name}}</td>
                                                    <td v-for="(item, index) of [1,2,3,4,5,6,7]" :key="index">
                                                        <v-tooltip top v-if="validateAccess('update_carparkrate')">
                                                            <template v-slot:activator="{ on }">
                                                            <span>
                                                                <a v-if="props.item.items[index]" v-on="on" id="custom-hover" @click.prevent="viewRate({row: props.item.items[index].dayslotid, row2: props.item.items[index], day: props.item.name})">{{`${props.item.items[index].start_hour} - ${props.item.items[index].end_hour}`}}</a>
                                                                <a v-else v-on="on" id="custom-hover" @click.prevent="viewRateNew({day: props.item.name, dayid: props.item.id})">{{`Rate ${index + 1}`}}</a>
                                                            </span>
                                                            </template>
                                                            <span>{{props.item.items[index] ? "Update Rate" : "Add Rate"}}</span>
                                                        </v-tooltip>
                                                        <span v-else>
                                                            <span v-if="props.item.items[index]" v-on="on">{{`${props.item.items[index].start_hour} - ${props.item.items[index].end_hour}`}}</span>
                                                            <span v-else v-on="on">{{`Rate ${index + 1}`}}</span>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </template>
                                        </v-data-table>
                                    <!--
                                    <v-expansion-panels
                                    v-model="panel"
                                    multiple
                                    >
                                    <v-expansion-panel
                                        v-for="(item,i) in form.days"
                                        :key="i"
                                    >
                                        <v-expansion-panel-header>{{ item.name }}</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row>
                                                <v-col cols="12" md="3" v-for="(child, i) in item.items" :key="i">
                                                    <v-card @click.prevent="viewRate(child.dayslotid, child, child)" flat outlined style="cursor: pointer" class="grow">
                                                        <v-card-title>Slot ID {{child.dayslotid}}</v-card-title>
                                                        <v-card-subtitle>Time Period: {{child.start_hour}} - {{child.end_hour}}</v-card-subtitle>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    </v-expansion-panels>
                                    -->
                                    </v-col>
                                    <v-col cols="12">
                                        <v-btn @click="viewMore(form, 'calculate')" tile small color="accent" type="button"><v-icon left x-small>fa fa-calculator</v-icon> Test Rate</v-btn>
                                    </v-col>
                                    
                                    <v-card-actions>
                                        <v-btn v-if="validateAccess('update_carparkrate')" class="mt-4" tile small color="primary" type="submit" :loading="btn_loading"><v-icon left x-small>fa fa-save</v-icon> Save</v-btn>
                                        <v-btn class="mt-4" tile small color="error" type="button" @click="goBack"><v-icon left x-small>fa fa-ban</v-icon> Cancel</v-btn>
                                        <v-btn v-if="validateAccess('delete_carparkrate')" @click="viewDelete(items, 'delete')" class="mt-4" tile small color="error" type="button"><v-icon left x-small>fa fa-trash</v-icon> Remove Rate Card</v-btn>
                                    </v-card-actions>
                                </v-row>
                            </v-container>
                        </v-form>
            <v-overlay
            :absolute="absolute"
            :value="overlay"
            >
                <v-progress-circular
                indeterminate
                color="primary"
                ></v-progress-circular>
            </v-overlay>
        </v-card>
        <v-snackbar
            v-model="swal.notification"
            top
            :color="swal.scolor"
            >
            {{ swal.message }}
        </v-snackbar>
        <alertbox :dialog="ma.alertmodal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
        <alertdelete :dialog="ma.modal_delete" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :item="ma.item" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></alertdelete>
        <edit :dialog="ma.modal_edit" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></edit>
        <editnew :dialog="ma.modal_add" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></editnew>
        <calculate :dialog="ma.modal_calculate" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></calculate>
    </div>
</template>

<script>
import timer from '../../assets/js/sleeper.js';
import {ERR} from '../../assets/js/errhandle.js';
import create from '.././modals/AddRate';
import edit from '.././modals/EditRate2';
import editnew from '.././modals/EditNewRate';
import calculate from '.././modals/CalculateRateCard';
import alertbox from '../modals/Alert';
import alertdelete from '../modals/AlertDeleteProfile';
import TextEditor from '../TextEditor';
import * as moment from "moment";
import * as tz from "moment-timezone";
import * as AppStorage from '../../assets/js/storage';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import {rule} from '../../assets/js/checkPerm';

export default {
    name: 'Update Car Park Rate',
    title: 'Update Car Park Rate',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Car Park',
                disabled: true,
                href: '#',
                },

                {
                text: 'Car Park Rate',
                disabled: true,
                href: '#',
                },

                {
                text: 'Update Car Park Rate',
                disabled: true,
                href: '#',
                }
            ],
            operator: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            items: {},
            search: '',
            tableloading: false,
            headers: [
                { text: 'Days', value: '1', width: '15%' },
                { text: 'Rate 1', value: 'r1', width: '12%' },
                { text: 'Rate 2', value: 'r2', width: '12%' },
                { text: 'Rate 3', value: 'r3', width: '12%' },
                { text: 'Rate 4', value: 'r4', width: '12%' },
                { text: 'Rate 5', value: 'r5', width: '12%' },
                { text: 'Rate 6', value: 'r6', width: '12%' },
                { text: 'Rate 7', value: 'r7', width: '12%' },
            ],
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                alertmodal: false,

                modal_add: false,
                modal_edit: false,
                modal_delete: false,

                modal_calculate: false
            },
            modalInfo: [],
            form: {
                cpid: '',
                cpcode: '',
                carparkname: '',
                ratecardid: '',
                ratecardname: {name: '', rule: []},
                cutofftime: '',
                entrygrace: {name: '', rule: []},
                exitgrace: {name: '', rule: []},
                createddatetime: '',
                updateddatetime: '',
                activestatus: '',
                defaultchosenratecardforcarpark: '',
                days: [
                    {id: 'mon', name: 'Monday', items: []},
                    {id: 'tue', name: 'Tuesday', items: []},
                    {id: 'wed', name: 'Wednesday', items: []},
                    {id: 'thu', name: 'Thursday', items: []},
                    {id: 'fri', name: 'Friday', items: []},
                    {id: 'sat', name: 'Saturday', items: []},
                    {id: 'sun', name: 'Sunday', items: []},
                    {id: 'phol', name: 'Public Holiday', items: []},
                ],
            },
            componentloader: false,
            role_selected: [],
            perm_list: [],
            select_all: false,
            btn_loading: false,
            access_record: [],
            overlay: false,
            absolute: true,
            loading: false,

            start: 0,
            timeout: null,
            rowHeight: 24,
            perPage: 25,
            focuscutoff: false,
            overlay_loader: false,
            readonly: false
        }
    },

    components: {create, edit, alertbox, 'text-editor': TextEditor, alertdelete, VueTimepicker, calculate, editnew},

    async created(){
        this.$vuetify.goTo(0);
        if (!this.validateAccess('update_carparkrate')){
            this.readonly = true;
        }
        this.initVal();
    },

    async beforeRouteLeave (to, from , next) {
        AppStorage.remove("rcdetails");
        next();
    },

    methods: {
        async getDetails(){
            let response = await this.$store.dispatch("getRateCardByID", {ratecardid: this.$route.params.id});
            if (response.data.code === 'AP000'){
                this.items = response.data.ratecardjson;
            }

            else{
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = `Unable to retrieve information. Please refresh and try again.`;
            }
        },

        async updateRateCard(){
            let entrygraceinminutes = moment.duration(this.form.entrygrace.name, 'minutes');
            let entrygrace = moment.utc(entrygraceinminutes.asMilliseconds()).format("HH.mm");

            let exitgraceinminutes = moment.duration(this.form.exitgrace.name, 'minutes');
            let exitgrace = moment.utc(exitgraceinminutes.asMilliseconds()).format("HH.mm");

            let cutofftime = moment(this.form.cutofftime, 'hh:mm a').format("H.mm");

            let activestatus = this.form.activestatus == true ? 1 : 0;
                
            let req = {
                ratecardid: this.form.ratecardid,
                name: this.form.ratecardname.name,
                entrygrace: Number(entrygrace),
                exitgrace: Number(exitgrace),
                cutoff: Number(cutofftime),
                activestatus: activestatus,
                carparkid: this.modalInfo.CarParkID
            };

            console.log('req', req);

            try{
                this.overlay_loader = true;
                let response = await this.$store.dispatch("updateRateCard", req)
                if (response.data.code === 'AP000'){
                        if (this.form.defaultchosenratecardforcarpark !== this.items.defaultchosenratecardforcarpark)
                        await this.$store.dispatch("chooseDefaultRateCard", {ratecardid: this.form.ratecardid, carparkid: this.modalInfo.CarParkID})
                        await this.getDetails();
                        await this.initVal();

                        this.$router.push({ name: 'Car Park Rate', query: {updated: 'true', carpark: this.form.cpid}});
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Unable to update.`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.overlay_loader = false;
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.overlay_loader = false;
            }
        },

        async addNewRateCardSlot(v){
            let req = {
                ratecardid: this.form.ratecardid,
                selectedday: v.day,
                activationdate: v.activationdate,
                deactivationdate: v.deactivationdate,
                start_hour: v.start_hour,
                end_hour: v.end_hour,
                rate_type: v.rate_type,
                rate: v.rate,
                max: v.max,
                continue: v.continue,
                subtime: v.subtime
            };

            if (req.subtime == undefined) delete req.subtime;

            console.log('req', req);

            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("addNewRateCardSlot", req)
                if (response.data.code === 'AP000'){
                        await this.getDetails();
                        await this.initVal();

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Slot has been added!`;
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Unable to update.`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        async updateExistingRateCardSlot(v){
            let req = {
                ratecardid: this.form.ratecardid,
                slotid: v.dayslotid,
                activationdate: v.activationdate,
                deactivationdate: v.deactivationdate,
                start_hour: v.start_hour,
                end_hour: v.end_hour,
                rate_type: v.rate_type,
                rate: v.rate,
                max: v.max,
                continue: v.continue,
                subtime: v.subtime,
                carparkid: this.modalInfo.CarParkID
            };

            if (req.subtime == undefined) delete req.subtime;

            console.log('req', req);

            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("updateExistingRateCardSlot", req)
                if (response.data.code === 'AP000'){
                        await this.getDetails();
                        await this.initVal();

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Slot has been updated!`;
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Unable to update.`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },
        

        async deleteProfile(){
            try{
                this.tableloading = true;
                let req = {ratecardid: this.form.ratecardid};
                let response = await this.$store.dispatch("deleteRateCard", req)
                
                if (response.data.code === 'AP000'){
                    this.$router.replace({ name: "Car Park Rate", query: {deleted: 'true', carpark: this.form.cpid}});
                }

                else{
                    this.tableloading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }
            }

            catch(err){
                console.log(err);
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        goBack(){
            this.$router.push({name: 'Car Park Rate'});
        },

        async initVal(){
            if (this.$route.query.row !== undefined) AppStorage.set("rcdetails", this.$route.query.row);
            this.$router.replace({'query': null});
            this.modalInfo = this.$route.query.row || AppStorage.get('rcdetails');
            this.modalInfo = JSON.parse(this.modalInfo);

            await this.getDetails();
            this.$router.replace({'query': null});
            this.form.ratecardid = this.items.ratecardid;
            this.form.ratecardname.name = this.items.name;
            this.form.cutofftime = moment(this.items.cutoff.toFixed(2).toString().replace('.', ':'),'hh:mm a').format('hh:mm a');
            this.form.defaultchosenratecardforcarpark = this.items.defaultchosenratecardforcarpark;

            let entrygrace = this.items.entrygrace.toFixed(2).toString().split('.');
            let graceentryhour = entrygrace[0] == '0' ? '' : `${entrygrace[0]} `;
            let graceentryminutes = entrygrace[1] == undefined ? '' : `${entrygrace[1]}`;
            let graceentryfullminutes = Number(graceentryhour) * 60 + Number(graceentryminutes);
            this.form.entrygrace.name = graceentryfullminutes;

            let exitgrace = this.items.exitgrace.toFixed(2).toString().split('.');
            let graceexithour = exitgrace[0] == '0' ? '' : `${exitgrace[0]} `;
            let graceexitminutes = exitgrace[1] == undefined ? '' : `${exitgrace[1]}`;
            let graceexitfullminutes = Number(graceexithour) * 60 + Number(graceexitminutes);
            this.form.exitgrace.name = graceexitfullminutes;

            this.form.cpid = this.modalInfo.CarParkID;
            this.form.cpcode = this.modalInfo.CarParkUserInputCode;
            this.form.carparkname = this.modalInfo.CarParkName;
            this.form.createddatetime = this.modalInfo.CreatedDateTime;
            this.form.updateddatetime = this.modalInfo.UpdatedDateTime;
            this.form.activestatus = this.items.activestatus;

            let days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun', 'phol'];

            for (let [id, it] of days.entries()){
                for(let [id2, it2] of this.items[it].entries()){
                    /*
                    it2.start_hour = moment(it2.start_hour.toFixed(2).toString().replace('.', ':'),'h:mm a').format('h:mm a');
                    it2.end_hour = moment(it2.end_hour.toFixed(2).toString().replace('.', ':'),'h:mm a').format('h:mm a');
                    */
                   
                    it2.start_hour = moment(it2.start_hour.toFixed(2).toString().replace('.', ':'),'HH:mm').format('HH:mm');
                    it2.end_hour = moment(it2.end_hour.toFixed(2).toString().replace('.', ':'),'HH:mm').format('HH:mm');

                    if (it2.rate_type != 'flat'){
                        let trimmedrate = Number(it2.rate_type.replace('time_', ''));
                        let rate = trimmedrate.toFixed(2).toString().split('.');
                        let ratehour = rate[0] == '0' ? '' : `${rate[0]} `;
                        let rateminutes = rate[1] == undefined ? '' : `${rate[1]}`;
                        let ratefullminutes = Number(ratehour) * 60 + Number(rateminutes);
                        it2.rate_type = ratefullminutes;
                    }
                }
            }

            this.form.days[0].items = this.items.mon;
            this.form.days[1].items = this.items.tue;
            this.form.days[2].items = this.items.wed;
            this.form.days[3].items = this.items.thu;
            this.form.days[4].items = this.items.fri;
            this.form.days[5].items = this.items.sat;
            this.form.days[6].items = this.items.sun;
            this.form.days[7].items = this.items.phol;
            
            //this.form.createddate = moment(new Date(this.items.emailCreatedDateTime)).format('DD-MM-YYYY hh:mm:ss A');
        },

        async beforeCreateForm(flag){
            this.form.ratecardname.rule = [
                v => !!v || "Name is required"
            ];

            this.form.entrygrace.rule = [
                v => v.length != 0 || "Entry Grace Period is required",
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form.exitgrace.rule = [
                v => v.length != 0 || "Exit Grace Period is required",
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            let self = this;

            setTimeout(function () {
             if (self.$refs.ct.validate()) {
                self.ma.alertmodal = true;
                //self.ma.modalInfo = {id: 1, name: 2};
                self.ma.modal_action_type = flag;
                    //self.addRateCard();
                }
            });
        },

        viewMore(row, flag){
            if (flag == 'calculate'){
                this.ma.modal_calculate = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        viewDelete(row, flag){
            console.log(row);
            if (flag == 'delete'){
                this.ma.modal_delete = true;
                this.ma.modalInfo = row;
            }

            this.ma.modal_action_type = flag;
        },

        viewRate(it){
            this.ma.customText = {};
            this.ma.modal_edit = true;
            if (it.row != undefined)
            this.ma.customText = {text1: it.row, item: it.row2, name: it.day};

            else{
                this.ma.customText = {name: it.day};
            }
            this.ma.modal_action_type = 'edit';
        },

        viewRateNew(it){
            this.ma.customText = {};
            this.ma.modal_add = true;
            if (it.row != undefined)
            this.ma.customText = {text1: it.row, item: it.row2, name: it.day};

            else{
                this.ma.customText = {name: it.day, dayid: it.dayid};
            }
            this.ma.modal_action_type = 'add';
        },

        onClosed(value){
            console.log(value);
            if (value == false){
                this.ma.alertmodal = value;
                this.ma.modal_delete = value;
                this.ma.modal_add = value;
                this.ma.modal_edit = value;
                this.ma.modal_calculate = value;
            }
        },

        onConfirm(value){
            console.log(value);
            if (value == true){
                this.ma.alertmodal = false;

                //this.addValuePass(formatteddays);

                if (this.ma.modal_action_type == 'delete')
                {
                    this.ma.modal_delete = false;
                    this.deleteProfile();
                }

                if (this.ma.modal_action_type == 'update')
                {
                    console.log('in');
                    this.ma.modal_edit = false;
                    let entrygraceinminutes = moment.duration(this.form.entrygrace.name, 'minutes');
                    let entrygrace = moment.utc(entrygraceinminutes.asMilliseconds()).format("H.mm");

                    let exitgraceinminutes = moment.duration(this.form.exitgrace.name, 'minutes');
                    let exitgrace = moment.utc(exitgraceinminutes.asMilliseconds()).format("H.mm");

                    let cutofftime = moment(this.form.cutofftime, 'hh:mm a').format("H.mm");

                    this.updateRateCard();
                }
            }

            //for rate slot edit existing
            if (value.flag == "edit"){
                this.ma.modal_edit = false;
                this.updateExistingRateCardSlot(value);
            }

            //for rate slot add new
            if (value.flag == "add"){
                this.ma.modal_add = false;
                this.addNewRateCardSlot(value);
            }
        },
    }
}
</script>